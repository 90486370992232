@import "../../styles/breakpoints";

@keyframes floater {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-40px);
	}
	100% {
		transform: translatey(0px);
	}
}

.container {
	height: 100%;
	min-height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.limbicLogo {
	position: relative;
	width: 200px;
	height: 200px;
	left: -150px;
	margin-bottom: -200px;
	top: -100px;
	transform: translateY(-20%);
	animation: floater 2.5s infinite;
	transition: ease 1.5s;

	@media only screen and (max-width: $breakpoint-medium) {
		display: none;
	}
}

.card {
	height: auto;
	padding: 50px;
	position: absolute;
	background: white;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	display: flex;
	flex-direction: column;
	max-width: 1200px;

	@media only screen and (max-width: $breakpoint-medium) {
		width: 90%;
		margin-top: 25px;
		padding: 30px;
	}

	@media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
		width: 80%;
		margin-top: 100px;
	}
	@media only screen and (min-width: $breakpoint-900p) {
		width: 60%;
		margin-top: 150px;
	}
}

.content {

		display: flex;
		justify-content: space-between;

		> div {
			width: 48%;
		}

		@media only screen and (max-width: $breakpoint-medium) {
			flex-direction: column-reverse;
			text-align: center;
			> div {
				width: 100%;
			}
		}
	}

.explanation {
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: $breakpoint-medium) {
		flex-direction: column-reverse;
	}
}

.qr {

	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		width: 100%;
		max-width: 500px;
	}

	@media only screen and (max-width: $breakpoint-medium) {
		align-self: center;
		> img {
			width: 40vh;
		}
	}
}

.downloadContainer {

	.downloadLinks {
		display: flex;
		justify-content: flex-start;
		max-width: 300px;

		> a > img {
			max-height: 40px;

			&:first-of-type {
				margin-right: 10px;
			}
		}

		@media only screen and (max-width: $breakpoint-medium) {
			margin: 32px auto;
			justify-content: space-around;
			> img {
				&:first-of-type {
					margin-right: 0;
				}
			}
		}
	}
}

.error {
	text-align: right;

	@media only screen and (max-width: $breakpoint-medium) {
		text-align: center;
	}
}




